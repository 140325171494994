import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { BaseSiteService } from '@spartacus/core';
import { getTextLengthOfElement } from '@util/functions/elements';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HighlightableConfigurationDisplayData, KurzConfigurationDisplayData } from '../types/kurz-foil-configuration-list.interface';



@Component({
  selector: 'app-kurz-configuration-display',
  templateUrl: './kurz-configuration-display.component.html',
  styleUrls: ['./kurz-configuration-display.component.scss']
})
export class KurzConfigurationDisplayComponent implements AfterViewInit {

  private configDataBehaviorSubject = new BehaviorSubject<KurzConfigurationDisplayData>(null);

  public showShipmentInfo: Observable<boolean>;

  get configData$(): Observable<KurzConfigurationDisplayData> {
    return this.configDataBehaviorSubject.asObservable();
  }

  get configData(): KurzConfigurationDisplayData {
    return this.configDataBehaviorSubject.value;
  }

  @Input()
  set configData(value: KurzConfigurationDisplayData) {
    this.configDataBehaviorSubject.next(value);
  }

  // gp - general plastics
  get isGP(): boolean {
    return this.configData?.configType === 'PLASTICS';
  }

  @Input()
  priceError: boolean;

  @Input()
  isStockPosition: boolean;

  /**
   * if this <app-kurz-configuration-display> is a part of a list of this component then often it is a requirement that the keys and the values are aligned.
   * this is easily achieved if the div block of the key has the same min-value as all the key div-blocks in all <app-kurz-configuration-display>s within this list.
   * To achieve that a css custom property is set in a common element of all components, which are in the list.<br>
   * - commonElementDepth is the number of parent elements the host element has until you finally reach this common element.
   */
  @Input()
  commonElementDepth: number = 0;


  /**
   * - commonElementMatcher is a selector, which matches with the above mentioned common element but not with all the elements between the host and this common element
   */
  @Input()
  commonElementMatcher: string;


  constructor(
    private elementRef: ElementRef,
    private utilCustomCSSPropertyService: UtilCustomCSSPropertyService,
    private baseSiteService: BaseSiteService
  ) {
    this.showShipmentInfo = this.baseSiteService.getActive()
      .pipe(map(site => site === 'kurz-us'));
  }

  ngAfterViewInit(): void {
    this.calculateGlobalMaxLengthForKeyLabels();
  }

  isAttributeHighlighted(configDataAttrName: HighlightableConfigurationDisplayData): boolean {
    const pool = this.configData?.highlighted || [];
    return pool.includes(configDataAttrName);
  }

  isNumber(x: number) {
    return typeof x === 'number';
  }

  /**
   * if this <app-kurz-configuration-display> is a part of a list of this component then often it is a requirement that the keys and the values are aligned.
   * this is easily achieved if the div block of the key has the same min-value as all the key div-blocks in all <app-kurz-configuration-display>s within this list.
   * To achieve that a css custom property is set in a common element of all components, which are in the list.<br>
   * - this function finds the common element with the help of commonElementDepth or commonElementMatcher.
   * - calculates the biggest min-width, that is needed for the textes rendered in the key div blocks
   * - compares it with the min-width value in the common global element and saves the bigger value in the same element
   */
  private calculateGlobalMaxLengthForKeyLabels() {

    const host = this.elementRef?.nativeElement as HTMLElement;
    let commonGlobalElement: HTMLElement = this.getCommonGlobalElement();

    if (commonGlobalElement) {

      const cssCustomPropertyName = '--min-width-of-app-kurz-configuration-display-key';

      let globalMinWidthKeyLength = parseFloat(this.utilCustomCSSPropertyService.getValue(cssCustomPropertyName, commonGlobalElement)) || 0;

      const allKeys = Array.from(host?.querySelectorAll('.configuration-key')) as HTMLElement[];

      let localMinWidthKeyTextLength: number = 0;

      allKeys.forEach(el => {
        const lengthObj = getTextLengthOfElement(el);
        localMinWidthKeyTextLength = Math.max(localMinWidthKeyTextLength, lengthObj.length);
      });

      globalMinWidthKeyLength = Math.max(localMinWidthKeyTextLength, globalMinWidthKeyLength);

      this.utilCustomCSSPropertyService.setValue(cssCustomPropertyName, globalMinWidthKeyLength + 'px', commonGlobalElement);

    }

  }

  private getCommonGlobalElement(): HTMLElement {
    const host = this.elementRef?.nativeElement as HTMLElement;
    let commonGlobalElement: HTMLElement = host;

    if (this.commonElementDepth) {

      let el: HTMLElement = host;
      let i = 0;

      for (i = 0; i < this.commonElementDepth; i++) {
        el = el.parentElement;
      }

      commonGlobalElement = el;
    }

    if (this.commonElementMatcher && typeof this.commonElementMatcher === 'string') {
      let el: HTMLElement = host;
      while (el) {
        if (el?.matches(this.commonElementMatcher)) {
          commonGlobalElement = el;
          el = null;
        } else {
          el = el.parentElement;
        }
      }
    }

    return (commonGlobalElement !== host) ? commonGlobalElement : null;
  }

}
