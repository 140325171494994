import { Component, Input } from '@angular/core';
import { KurzCartEntry } from '../../types/kurz-cart.interface';
import { CuttingEntry } from '../../types/kurz-foil-configuration-list.interface';

@Component({
  selector: 'app-kurz-cutting-display',
  templateUrl: './kurz-cutting-display.component.html',
  styleUrls: ['./kurz-cutting-display.component.scss']
})
export class KurzCuttingDisplayComponent {

  @Input()
  cuttings: KurzCartEntry[];

  constructor() { }
}
