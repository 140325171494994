
<ng-container *ngIf="configData$ | async as configData">
  <div class="foil-config-display" >

    <ng-container *ngIf="configData.core?.name">
      <div class="configuration-key">{{'configDisplay.core' | cxTranslate}}</div>
      <div class="configuration-value configuration-no-wrap {{ isAttributeHighlighted('core') ? 'configuration-value-highlighted' : '' }}">{{ configData.core?.name }}</div>
    </ng-container>

    <ng-container *ngIf="isGP && (configData?.finishingType?.name || configData?.finishingType?.code)">
      <div class="configuration-key">{{'configDisplay.finishingType' | cxTranslate}}</div>
      <div class="configuration-value {{ isAttributeHighlighted('finishingType') ? 'configuration-value-highlighted' : '' }}">{{ configData?.finishingType?.name || configData?.finishingType?.code }}</div>
    </ng-container>

    <ng-container *ngIf="configData.length">
      <div class="configuration-key">{{'configDisplay.length' | cxTranslate}}</div>
      <div class="configuration-value {{ isAttributeHighlighted('length') ? 'configuration-value-highlighted' : '' }}">{{ configData.length | kurzLength : configData.lengthUnitCode }}</div>
    </ng-container>

      <ng-container *ngIf="configData.width">
        <div class="configuration-key">{{'configDisplay.width' | cxTranslate}}</div>
        <div class="configuration-value {{ isAttributeHighlighted('width') ? 'configuration-value-highlighted' : '' }}">
          {{ configData.width | kurzWidth : configData.widthUnitCode }}
          <div class="special-width-configuration" *ngIf="configData.minimumQuantity > 1">{{ 'configDisplay.specialWidth' | cxTranslate }}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="configData.quantity >= 0">
      <div class="configuration-key">{{'configDisplay.quantity' | cxTranslate}}</div>
      <div class="configuration-value {{ isAttributeHighlighted('quantity') ? 'configuration-value-highlighted' : '' }}">{{ configData.quantity }}</div>
    </ng-container>

    <ng-container *ngIf="configData.totalArea >= 0">
      <div class="configuration-key">{{'configDisplay.totalArea' | cxTranslate}}</div>
      <div class="configuration-value {{ isAttributeHighlighted('totalArea') ? 'configuration-value-highlighted' : '' }}">{{ configData.totalArea | kurzArea : configData.areaUnitCode }}</div>
    </ng-container>

    <ng-container *ngIf="configData.price && configData.unit">
      <div class="configuration-key">{{'configDisplay.price' | cxTranslate}}</div>
      <div class="configuration-value {{ isAttributeHighlighted('price') ? 'configuration-value-highlighted' : '' }}">
        <!-- {{ configData.price | kurzPrice : configData.currencyIso }} / {{ configData.unit | kurzUnitTranslate }} -->
        {{ configData.price | kurzPricePerUnit : configData.unit : configData.unitFactor : configData.currencyIso }}
      </div>
    </ng-container>

    <ng-container *ngIf="!configData.hidePriceBase && configData.priceBaseType && (isAttributeHighlighted('priceBaseType') || configData.priceBaseType !== 'STANDARD' || configData.priceBaseType && configData.showPriceBaseTypeExplicitly)">
      <div class="configuration-key">{{'configDisplay.priceBase' | cxTranslate}}</div>
      <div [class.price-error]="priceError" class="configuration-value {{ isAttributeHighlighted('priceBaseType') ? 'configuration-value-highlighted' : '' }}">

        <ng-container *ngIf="configData.priceBaseType === 'STANDARD'">{{ 'pricelist.priceBaseType.standard' | cxTranslate }}</ng-container>

        <ng-container *ngIf="configData.priceBaseType === 'CONTRACT' || configData.priceBaseType === 'PROJECT'">{{configData.contractName}}</ng-container>

        <ng-container *ngIf="configData.priceBaseType === 'TAILOR_MADE'">{{ 'pricelist.priceBaseType.tailorMade' | cxTranslate }}</ng-container>

        <ng-container *ngIf="configData.priceBaseType === 'BEST_IN_CLASS'">{{ 'pricelist.priceBaseType.bestInClass' | cxTranslate }}</ng-container>

        <ng-container *ngIf="configData.priceBaseType === 'SCALE_PRICE'">{{ 'pricelist.priceBaseType.scalePriceString' | cxTranslate : {'scaleTranslation': (configData.scaleUnit | kurzUnitTranslate : configData.scaleUnitFactor) } }}</ng-container>

      </div>
    </ng-container>

    <ng-container *ngIf="isStockPosition">
      <div class="configuration-key">{{'configDisplay.productOrigin' | cxTranslate}}</div>
      <div class="configuration-value">{{'configDisplay.stockPosition' | cxTranslate}}</div>
      <div class="shipment-info" *ngIf="showShipmentInfo | async">{{'configDisplay.shipmentInfo' | cxTranslate}}</div>
    </ng-container>

    <ng-container *ngIf="isNumber(configData.totalPrice)">
      <div class="configuration-key">{{'configDisplay.totalPrice' | cxTranslate}}</div>
      <div class="configuration-value {{ isAttributeHighlighted('price') ? 'configuration-value-highlighted' : '' }}">
        <app-kurz-differently-styled-value
          [valueObjects]="(configData.totalPrice) | kurzPrice : configData.currencyIso | differentlyStyledFirstNumber : [null, 1, {fontWeight: 'bold'}] : [null, 0.8] "
        ></app-kurz-differently-styled-value>
      </div>
    </ng-container>

  </div>

  <div class="cutting-display" *ngIf="configData.isMasterRoll || configData.isCutSteps">
    <app-kurz-cutting-display [cuttings]="configData.cuttingEntries"></app-kurz-cutting-display>
  </div>

</ng-container>
