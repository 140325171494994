<ng-container *ngFor="let cutting of cuttings">
  <div class="cutting">
    <app-icon-button
      iconType="CUT"
      [colorTheme]="['SECONDARY']"
      disabled="true"
    ></app-icon-button>
    <span class="label">{{ (cutting.masterRoll.remainder ? 'configDisplay.cuttingRemainder' : 'configDisplay.cutting') | cxTranslate }}</span>
    <span class="quantity">{{ cutting.quantity }}x</span>
    <span class="foil-width">{{ cutting.foilWidth }}</span>
  </div>
</ng-container>
